import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import {
     connect, useDispatch
     // , useSelector
} from "react-redux";
import TopNavigation from "./../../../components/TopNavigation";
import LinearNavigator from "./../../../components/LinearNavigator";
import {
     getFormValues,
     getModuleDetails,
     getRoadmapDetails,
     getLearningToolDetails,
     updatePercentage,
     toggleLanguage,
     getSessionData
} from "./../redux_actions";
import "./conduct.css";
import { isACP } from "../../../utilities/common";
import i18next from "i18next";
import store from "../../../Redux_Store";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
const { innerHeight: WinHeight } = window;
const { innerWidth: WinWidth } = window;

let Conduct = (props) => {
     let module = {}
     const location = useLocation();
     const moduleId = new URLSearchParams(location.search).get('moduleId');
     let sessionId = props.match.params.sessionId;

     let { user_token } = props.auth;
     let { user_uid } = props.auth;
     let { availableModule } = props.app;
     const dispatch = useDispatch();
     // const [close, setClose] = useState(false)
     const [selectedLang, setSelectedLang] = useState(i18next?.language ? i18next?.language : "en");

     //development purpose to create your own session
     useEffect(() => {
          let a = async () => getSessionData({ user_token, sessionId })
          if (user_token && sessionId) {
               a().then(res => {
                    console.log("res", res)
                    if (res && res[0])
                         store.dispatch({ type: "CREATESESSION_SUCCEEDED", payload: res[0] })
               })
          }
     }, [sessionId, user_token])
     // useEffect(() => {
     //      let s_id = [{
     //           "_id": "63940e97054bc4001dddbe0f",
     //           "facilitators": [
     //                "6390253f6bf66f001e8fba57",
     //                "63934846054bc4001ddd8992"
     //           ],
     //           "instances": [

     //           ],
     //           "end": true,
     //           "sessionName": "2022-12-10T04:38:49.776Z",
     //           "moduleId": "639026c14e59d8ad30508d71",
     //           "coachId": "6390253f6bf66f001e8fba57",
     //           "noOfStudents": "0",
     //           "batchId": "63940e7a054bc4001dddbddd",
     //           "sessionid": "Session-10122022-7",
     //           "sessionDate": "12/10/2022",
     //           "sessionStartTime": "4:44:07 AM",
     //           "createdAt": "2022-12-10T04:44:07.849+0000",
     //           "updatedAt": "2022-12-10T07:11:36.770+0000",
     //      }, {
     //           "_id": "639431e576c363001eeafde2",
     //           "facilitators": [
     //                "6390253f6bf66f001e8fba57",
     //                "63934846054bc4001ddd8992"
     //           ],
     //           "instances": [

     //           ],
     //           "end": true,
     //           "sessionName": "2022-12-10T07:09:27.370Z",
     //           "moduleId": "63905e1cbfed9cbf4d91b235",
     //           "coachId": "6390253f6bf66f001e8fba57",
     //           "noOfStudents": "0",
     //           "batchId": "63940e7a054bc4001dddbddd",
     //           "sessionid": "Session-10122022-8",
     //           "sessionDate": "12/10/2022",
     //           "sessionStartTime": "7:14:45 AM",
     //           "createdAt": "2022-12-10T07:14:45.616+0000",
     //           "updatedAt": "2022-12-10T09:10:57.765+0000",               
     //      }, {
     //           "_id": "6394561776c363001eeb02ce",
     //           "facilitators": [
     //                "6390253f6bf66f001e8fba57",
     //                "63934846054bc4001ddd8992"
     //           ],
     //           "instances": [

     //           ],
     //           "end": true,
     //           "sessionName": "2022-12-10T09:43:52.879Z",
     //           "moduleId": "63906bcebfed9cbf4d91b23d",
     //           "coachId": "6390253f6bf66f001e8fba57",
     //           "noOfStudents": "0",
     //           "batchId": "63940e7a054bc4001dddbddd",
     //           "sessionid": "Session-10122022-10",
     //           "sessionDate": "12/10/2022",
     //           "sessionStartTime": "9:49:11 AM",
     //           "createdAt": "2022-12-10T09:49:11.041+0000",
     //           "updatedAt": "2022-12-10T12:03:02.352+0000"               
     //      }, {
     //           "_id": "6394757b76c363001eeb0467",
     //           "facilitators": [
     //                "6390253f6bf66f001e8fba57",
     //                "63934846054bc4001ddd8992"
     //           ],
     //           "instances": [

     //           ],
     //           "end": true,
     //           "sessionName": "2022-12-10T11:57:49.738Z",
     //           "moduleId": "6390711abfed9cbf4d91b24c",
     //           "coachId": "6390253f6bf66f001e8fba57",
     //           "noOfStudents": "0",
     //           "batchId": "63940e7a054bc4001dddbddd",
     //           "sessionid": "Session-10122022-11",
     //           "sessionDate": "12/10/2022",
     //           "sessionStartTime": "12:03:07 PM",
     //           "createdAt": "2022-12-10T12:03:07.943+0000",
     //           "updatedAt": "2022-12-10T14:20:09.254+0000",               
     //      }]

     //      store.dispatch({ type: "USE_MY_SESSION", payload: s_id[0] })
     // }, [])
     module = { ...props.app.moduleDetails[moduleId] };
     let roadMaps =
          props.app.moduleDetails[moduleId] &&
          props.app.moduleDetails[moduleId].organiser &&
          props.app.moduleDetails[moduleId].organiser.roadmaps &&
          Object.keys(props.app.moduleDetails[moduleId].organiser.roadmaps).sort(
               (a, b) => {
                    return (
                         props.app.moduleDetails[moduleId].organiser.roadmaps[a].order -
                         props.app.moduleDetails[moduleId].organiser.roadmaps[b].order
                    );
               }
          );

     let firstRoadMapId = roadMaps && roadMaps[0];
     // let firstRoadmap = props.app.roadmaps[roadMaps[0]];
     let firstRoadmap = props.app.roadmaps[firstRoadMapId];
     let sortedUnitsOfFirstRoadmap = firstRoadmap
          ? Object.keys(firstRoadmap.LearningTools).sort((a, b) => {
               return firstRoadmap.LearningTools[a] - firstRoadmap.LearningTools[b];
          })
          : null;
     let firstTool = sortedUnitsOfFirstRoadmap
          ? sortedUnitsOfFirstRoadmap[0]
          : null;
     let [isComponentLoaded, updateComponentLoadStatus] = useState(false);

     useEffect(() => {
          // load module details
          props.getModuleDetails({ id: moduleId, token: user_token }).then((res) => {
               if (res.payload) {
                    let roadMaps =
                         (res.payload.data.organiser.roadmaps &&
                              Object.keys(res.payload.data.organiser.roadmaps).sort((a, b) => {
                                   return (
                                        res.payload.data.organiser.roadmaps[a].order -
                                        res.payload.data.organiser.roadmaps[b].order
                                   );
                              })) ||
                         [];

                    roadMaps.forEach((rdId) => {
                         props
                              .getRoadmapDetails({ id: rdId, token: user_token })
                              .then((ress) => {
                                   if (ress) {
                                        Object.keys(ress.payload.data.LearningTools).forEach((uId) => {
                                             props.getLearningToolDetails({ id: uId, token: user_token });
                                        });
                                   }
                              });
                    });
               }
          });
          updateComponentLoadStatus(true);

          const { activeConductSessioId } = props.app;
          if (activeConductSessioId && props.app.continueConducts) {
               const session = props.app.continueConducts.filter(
                    (c) => c.id === activeConductSessioId
               );

               props.getFormValues({
                    sessionId: activeConductSessioId,
                    token: user_token,
               });

               if (session) {
                    dispatch({ type: "CREATESESSION_SUCCEEDED", payload: session[0] });
               }
          }
     }, [isComponentLoaded]);

     let [currentUnit, updatecurrentUnit] = useState(props.app.activeContinueConductDetails.learningToolsId ? props.app.activeContinueConductDetails.learningToolsId : null);
     let [currentScene, setcurrentScene] = useState(props.app.activeContinueConductDetails.currentSlideIndex ? props.app.activeContinueConductDetails.currentSlideIndex : 0);
     let [currentRoadmap, setCurrentRoadmap] = useState(props.app.activeContinueConductDetails.roadmapId ? props.app.activeContinueConductDetails.roadmapId : null);
     let availableModules = availableModule;

     let slideHeight_, slideWidth_, slideHeight, slideWidth, gutter;
     gutter = WinWidth > 1200 ? 60 : (1200 - WinWidth < 120 ? (1366 - WinWidth) / 2 : 4)
     slideHeight_ = WinHeight - 100 - 4;
     slideWidth_ = WinWidth * 0.68 - gutter / 2;
     //slideWidth_ = slideHeight * 16 / 9;
     if ((slideWidth_ / slideHeight_) < (16 / 9)) {
          //height will hit first
          slideWidth = slideWidth_;
          slideHeight = slideWidth_ * 9 / 16;
     } else {
          slideHeight = slideHeight_;
          slideWidth = slideHeight_ * 16 / 9
     }
     if (isACP)
          slideWidth = (slideHeight * 4) / 3;


     return (
          <div className={"conduct-container"}>
               {/* <TopNavigation
        data={module}
        isHindi={props.app.isHindi}
        toggleLanguage={toggleLanguage}
        currentUnit={currentUnit || firstTool}
        units={props.app.learningTools}
        currentScene={currentScene} /> */}

               <LinearNavigator
                    moduleData={module}
                    slideWidth={slideWidth}
                    slideHeight={slideHeight}
                    currentUnit={currentUnit || firstTool} //ToDo: null should be replaced with usermodule variable
                    currentRoadmap={currentRoadmap || firstRoadMapId}
                    units={props.app.learningTools}
                    roadmaps={props.app.roadmaps}
                    updatecurrentUnit={updatecurrentUnit}
                    setcurrentScene={setcurrentScene}
                    currentScene={currentScene}
                    user_uid={user_uid}
                    user_token={user_token}
                    availableModules={availableModules}
                    drawerVariant="permanent"
                    margin="2.5rem"
                    typPC="Conduct"
                    setCurrentRoadmap={setCurrentRoadmap}
                    app={props.app}
                    auth={props.auth}
                    selectedLang={selectedLang}
                    setSelectedLang={setSelectedLang}
               />
          </div>
     );
};

const mapStateToProps = (state) => ({ auth: state.auth, app: state.app });
function matchDispatchToProps(dispatch) {
     return bindActionCreators(
          {
               getRoadmapDetails,
               getLearningToolDetails,
               getModuleDetails,
               updatePercentage,
               getFormValues,
               toggleLanguage
          },
          dispatch
     );
}

export default connect(mapStateToProps, matchDispatchToProps)(Conduct);
