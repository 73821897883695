import React, { useEffect, useState } from 'react'
import LeftNav from '../IndianRailways/LeftNav';
import './batches.css'
import './batchDetails.css'
import EnhancedTable from './TableMUI';
import BatchDetails from './BatchDetails';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { getCoachAnalytics } from '../application/redux_actions';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';

const Card = ({ smallText, largeText }) => {
    return (
        <div className='bold-center-text'>
            <div className='large-text'>{largeText}</div>
            <div className='smaall-text'>{smallText}</div>
        </div>
    )
}

const Batches = () => {
    const [batchList, setBatchList] = useState(null);
    const [snapshot, setSnapshot] = useState({});
    const [selected, setSelected] = useState(undefined);

    let user_token = useSelector(state => state.auth?.user_token)
    let user = useSelector(state => state.app.user)
    let userId = user?._id;
    console.log("userId", userId)
    // let userId = '65602b12c7a4ec0012d8a94f' || user?._id;
    useEffect(async () => {
        if (user_token && userId) {
            await getCoachAnalytics({ user_token, userId }).then(res => {
                if (res && res.data && res.snapshot) {
                    setBatchList(res.data);
                    setSnapshot(res.snapshot);
                }
                else { setBatchList([]) }
            })
        }
    }, [user_token, userId])



    const handleBatchClick = (batch) => {
        setSelected(batch);
    }

    if (batchList === null) {
        return <div className={"dashboard-cntr bg-gray"}>
            <LeftNav />
            <div style={{ width: '100%', height: "100%", textAlign: "center", alignContent: 'center' }}>Fetching Data For You...</div>
        </div>
    }


    return (
        <div className={"dashboard-cntr bg-gray"}>
            <LeftNav />

            {batchList?.length !== 0 ?
                <div className="content">
                    <div className="your-batches">Your batches</div>
                    {selected ? <>
                        <Button onClick={() => setSelected(undefined)}> <ArrowBackOutlinedIcon style={{ marginLeft: "1rem" }} /> Back</Button>
                        <div>
                            <BatchDetails userId={userId} selected={selected} batchList={batchList} />
                        </div>
                    </> :
                        <>
                            {batchList && <div className='flex justify-around'>
                                {Object.keys(snapshot || {}).map((item, index) => {
                                    return <Card key={item} smallText={item} largeText={Object.values(snapshot)[index]} />
                                })}
                            </div>
                            }

                            {batchList && batchList?.length !== 0 ? <EnhancedTable defaultRows={5} list={batchList} handleBatchClick={handleBatchClick} /> : null}
                        </>}
                </div> :
                <div style={{ width: '100%', height: "100%", textAlign: "center", alignContent: 'center' }}>Data will be available once you start conducting batches to the field participants...</div>
            }

        </div>
    )
}

export default Batches;


