import React, { Component, useEffect, useState } from 'react'
import { createNewBatch, createSession, endSession, getAllSessionsByBatch, getBatchForCoachAndModule, getUser, updateBatch } from '../../application/redux_actions'
import moment from 'moment'
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ScheduleIcon from '@material-ui/icons/Schedule';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { Button, TextField, InputLabel, MenuItem, FormControl, Select, makeStyles, CircularProgress, FormHelperText } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { v4 } from 'uuid';
import ConditonalFilter from '../../singleAuth/Auth/ConditonalFilter';
import store from '../../../Redux_Store';
import { Redirect } from "react-router";
import { ContactSupportRounded } from '@material-ui/icons';
import EndSessionPopup from '../../../components/LinearNavigator/EndSessionPopup';
import Autocomplete from '@material-ui/lab/Autocomplete';

const ComboBox = ({ checkFields, options, handleChange, value, newBatchCretion = false }) => {
    return (
        <Autocomplete
            id="combo-box-demo"
            fullWidth
            style={{}}
            options={options}
            value={value}
            filterOptions={(options, state) => {
                let defaultOptions = [{ fullname: "Conducting Session Alone", id: 1, phone: '' }]
                if (state.inputValue.length == 0 || state.inputValue.length < 5 && newBatchCretion) {
                    return defaultOptions;
                }
                else {
                    let newOptions = []
                    options.forEach((element) => {
                        if (element.fullname.replace(",", "").toLowerCase().includes(state.inputValue.toLowerCase()) || element?.phone?.replace(",", "").toLowerCase().includes(state.inputValue.toLowerCase()))
                            newOptions.push(element)
                    });
                    return newOptions.length == 0 ? defaultOptions : newOptions;
                }
            }}
            onChange={(ev, value) => { handleChange(value) }}
            getOptionLabel={(option) => {
                return option.fullname == 'Conducting Session Alone' ? `${option?.fullname}` : `${option?.fullname}-${option?.phone}`
            }}
            renderInput={(params) => <TextField
                type={newBatchCretion ? value.fullname ? '' : 'number' : ''}
                error={!checkFields || value.fullname?.length > 0 ? false : true}
                helperText={!checkFields || value.fullname?.length > 0 ? '' : "Please enter your Cotrainer's phone number"}
                label={"Please enter your Cotrainer's phone number"} {...params} variant="outlined" />}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    root: {},
    paper: {
        width: '45%',
        height: '96%',
        borderRadius: '24px',
        overflow: 'hidden'
    },
    textFieldClass: {
        fontSize: '14px',
    },
    buttonLabel: {
        fontFamily: 'tablet-gothic !important'
    },
    buttonRoot: {
        '&:hover': {
            backgroundColor: '#e4001b !important',
            color: 'white',
        }
    },
    select: {
        "& .Muipaper-root": {
            color: 'red'
        },
        "& ul": {
            width: '37vw !important',
        },
        "& li": {
            textOverflow: ' clip',
            overflow: 'hidden',
            whiteSpace: 'inherit'
        },
    },
    selectBorder: {
        fontSize: '1rem',
        fontWeight: 400,
        color: '#49454F',
        display: 'flex',
        alignItems: 'center',
        background: '#f1f1f1',
        overflow: 'hidden',
        whiteSpace: 'normal',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent !important',
        },
        "&:hover .MuiOutlinedInput-root": {
            borderColor: 'black'
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: 'black'
        }
    },
    inputEmpty: {
        fontSize: '1rem',
        fontWeight: 400,
        color: '#49454F',
        display: 'flex',
        alignItems: 'center',
        background: '#FFFFFF',
        overflow: 'hidden',
        whiteSpace: 'normal',
        "&.MuiFormHelperText-root.Mui-error": {
            color: 'green !important',
        },
    },
    inputFilled: {
        fontSize: '1rem',
        fontWeight: 400,
        color: '#49454F',
        display: 'flex',
        alignItems: 'center',
        background: '#f1f1f1',
        overflow: 'hidden',
        whiteSpace: 'normal',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent !important',
        },
        "&:hover .MuiOutlinedInput-root": {
            borderColor: 'black'
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: 'black'
        },
        "&.MuiFormHelperText-root.Mui-error": {
            color: 'pink !important',
        },
    },
    selectFilled: {
        fontSize: '1rem',
        fontWeight: 400,
        color: '#49454F',
        display: 'flex',
        alignItems: 'center',
        background: '#f1f1f1',
        overflow: 'hidden',
        whiteSpace: 'normal',
    },
    newOutlinedSelect: {
        fontSize: '1rem',
        fontWeight: 400,
        color: '#49454F',
        display: 'flex',
        alignItems: 'center',
        background: '#f1f1f1',
        overflow: 'hidden',
        whiteSpace: 'normal',
    },
    outlinedSelectIcon: {
        width: '2em',
        height: "2em",
        top: 'unset'
    },
    batchInputRoot: {}
}));
const BatchDropDown = ({ moduleIndexText, handleCloseInfo, moduleIndex, setRedirect, setEndSessionPopup, __moduleId, newBatchComponent, firstModule, moduleDetails, createSessionFunction, sessionsForModule, checkFields, red_coTrainersForBatch, batchDetails, selectedBatch, latestBatch, setBatchDetails, setCheckFields, resumeSession, endSession }) => {
    const classes = useStyles();

    const _batchId = selectedBatch || latestBatch;

    let { meta, prev, resume, } = sessionsForModule && _batchId && sessionsForModule?.[_batchId] || {};

    let { moduleId, sessionDate, updatedAt, end, _id } = prev || {};
    const _meta = Object.keys(meta || {}).filter(k => !['No of Participants', 'programId'].includes(k)).map(k => meta[k]) || []
    let description = moduleId?.description || moduleDetails?.[moduleId]?.description || '';
    let options = Object.values(sessionsForModule || {}).map((batch) => batch?.batchName).reverse() || [];
    let batchName = sessionsForModule && _batchId && sessionsForModule?.[_batchId] && sessionsForModule?.[_batchId].batchName;
    let { resumeAbleSession, todaySession } =
        Object.keys(sessionsForModule || {})
            .filter(b => sessionsForModule[b]?.resume?.moduleId === __moduleId)
            .filter(b => sessionsForModule[b]?.resume?.end === false)
            .sort((a, b) => sessionsForModule[a].resume.createdAt - sessionsForModule[b].resume.createdAt)
            .reduce((res, b) => {
                let sessionDate = moment(sessionsForModule[b].resume.createdAt).format('DD//MM/YYYY');
                let todayDate = moment().format('DD//MM/YYYY');
                if (sessionDate == todayDate) res.todaySession.push(b);
                res.resumeAbleSession.push(b)
                return res;
            }, { resumeAbleSession: [], todaySession: [] });

    let [showBatchDropDown, setShowBatchDropDown] = useState(todaySession.length == 0 ? true : false);

    const handleChange = (event) => {
        Object.values(sessionsForModule || {}).filter(batch => batch.batchName === event.target.value).map(batch =>
            setBatchDetails((old) => ({
                ...old,
                selectedBatch: batch?.['_id']
            })))
    };


    const _moduleName = moduleDetails?.[__moduleId]?.description;


    const NewResumeSession = () => {
        let [showResumableSession, setShowResumableSession] = useState(todaySession.length == 0 ? true : false);
        function resumeComponent(b, today) {
            let { meta, resume } = sessionsForModule && b && sessionsForModule?.[b] || {};
            let { _id, sessionDate } = resume || {};
            const _meta = Object.keys(meta || {}).filter(k => !['No of Participants', 'programId'].includes(k)).map(k => meta[k]) || []
            let batchName = sessionsForModule && b && sessionsForModule?.[b] && sessionsForModule?.[b].batchName;

            return <div key={_id} style={{ display: 'flex', fontSize: '0.9rem', width: '100%', borderRadius: 8, border: '1px solid #cccccc', padding: '10px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, fontSize: "0.8rem" }}>
                    <div style={{ fontWeight: 600, fontSize: "1rem" }}>Batch Name: {batchName || ''}</div>
                    <div style={{ display: "flex", fontSize: "0.8rem", gap: '0.5rem' }}>
                        {_meta?.map((_v, i) => { return <div>{`${_v}${i == _meta?.length - 1 ? '' : ','}`}</div> })}
                    </div>
                    <div >Created: {today ? 'Today' : moment(sessionDate).format('DD/MM/YYYY') || ''}</div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '30%', width: '100%', alignItems: 'flex-end', justifyContent: 'center' }}>
                    <div onClick={() => resumeSession(_id)}
                        style={{ padding: '0px', color: '#ba1f00', fontWeight: 700, cursor: 'pointer' }}
                    >
                        RESUME
                    </div>
                    <div onClick={() => setEndSessionPopup({ open: true, sessionId: _id })} style={{ color: "#999999", padding: '0px', textTransform: 'none', textDecoration: "underline", cursor: 'pointer' }}>end session</div>
                </div>
            </div>
        }
        return <>
            <div className='scrollDiVisible' style={{ padding: '0.5em 1em', display: "flex", position: "relative", flexDirection: "column", gap: '0.9rem', overflowY: "auto", maxHeight: '54vh', }}>
                <div style={{ color: "#999999", fontSize: "1rem", fontWeight: 400, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {moduleIndexText ? `${moduleIndexText}: ${_moduleName}` : `${moduleIndex + 1}: ${moduleDetails?.[moduleId]?.description}`}
                    < Icon style={{ cursor: "pointer" }} onClick={() => {
                        handleCloseInfo()
                        store.dispatch({ type: 'EMPTY_COTRAINERS' })
                    }} path={mdiClose}
                        size={1}
                        color='black' />
                </div>
                {!showResumableSession && todaySession?.length > 0 && <div style={{}}>
                    <div style={{ display: "flex", gap: '1rem', alignItems: "center" }}>
                        <div style={{ background: "white", fontSize: '0.8rem', fontWeight: 700, color: "#000000", fontSize: '20px' }}>Yet to complete</div>
                        <div style={{ flexGrow: 1, height: "0px", borderRadius: 8, background: '#bfbaba' }}></div>
                    </div>
                </div>}
                {!showResumableSession && todaySession.map(b => resumeComponent(b, true))}
                {showResumableSession ? <>
                    {resumeAbleSession?.length > 0 &&
                        <div style={{}}>
                            <div style={{ display: "flex", gap: '1rem', alignItems: "center" }}>
                                <div style={{ background: "white", fontSize: '0.8rem', fontWeight: 700, color: "#000000", fontSize: '20px' }}>Yet to complete</div>
                                <div style={{ flexGrow: 1, height: "0px", borderRadius: 8, background: '#bfbaba' }}></div>
                            </div>
                        </div>}
                    {showResumableSession && resumeAbleSession.map(b => resumeComponent(b, false))}
                </> : <div style={{ textDecoration: "underline", fontSize: "0.8rem", padding: "0.5rem 1rem", textAlign: "right", cursor: "pointer", color: "#999999", fontSize: '14px', margin: '-1.5vh 0vh 2vh 0vh' }} onClick={() => setShowResumableSession(true)}>view older</div>}
            </div >
            <div style={{ padding: '0.5rem 1rem' }}>
                <div style={{ display: "flex", padding: "0.8rem", justifyContent: "space-between", gap: "1rem", backgroundColor: '#f1f1f1', alignItems: "center" }}>
                    <div style={{ fontWeight: 700, fontSize: "1.1rem", }}>To conduct a new session</div>
                    <BottomButton
                        style={{ width: '35%', margin: 0 }}
                        redirect={{}}
                        setCheckFields={{}}
                        _validtor={() => false}
                        text={firstModule ? 'CREATE BATCH' : 'CHOOSE BATCH'}
                        onClickFunction={() => setShowBatchDropDown(!showBatchDropDown)}
                    />
                </div>
            </div>
        </>
    }

    let resumeAbleSessionForBatch = sessionsForModule?.[_batchId]?.resume?.end === false ? sessionsForModule?.[_batchId]?.resume._id : null

    if (firstModule) {
        return <div style={{ display: 'flex', flexDirection: 'column' }}>
            {!showBatchDropDown ? <NewResumeSession /> : newBatchComponent('12123', false)}
            {!showBatchDropDown ? null : <div style={{ fontSize: "0.8rem", padding: "0.5rem 1rem", textAlign: "right", cursor: "pointer", color: "#999999", fontSize: '14px', margin: '-1.5vh 0vh 2vh 0vh' }} onClick={() => setShowBatchDropDown(!showBatchDropDown)}>view older</div>}
        </div>
    }
    else {
        return <div>
            {!showBatchDropDown ? <NewResumeSession /> :
                <div style={{ padding: '0.5rem 1rem', }}>
                    <div style={{ color: "#999999", fontSize: "1rem", fontWeight: 400, display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: "0rem 0rem 2.5rem" }}>
                        {moduleIndexText ? `${moduleIndexText}: ${_moduleName}` : `${moduleIndex + 1}: ${moduleDetails?.[moduleId]?.description}`}
                        < Icon style={{ cursor: "pointer" }} onClick={() => {
                            handleCloseInfo()
                            store.dispatch({ type: 'EMPTY_COTRAINERS' })
                        }} path={mdiClose}
                            size={1}
                            color='black' />
                    </div>
                    {batchName ?
                        <div style={{ display: 'flex', flexDirection: 'column', borderRadius: 8, fontSize: 14, background: '#F8F8F8', marginBottom: "1rem" }}>

                            <FormControl variant="outlined" style={{}} fullWidth >
                                <InputLabel id="ir-select-batch-label" >{'Select Batch Name'}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label={'Select Batch Name'}
                                    value={batchName}
                                    displayEmpty
                                    classes={{ outlined: classes.newOutlinedSelect, }}
                                    onChange={handleChange}
                                >
                                    {options.map(opt => <MenuItem
                                        style={{ fontSize: '1rem', fontWeight: 400, color: '#49454F' }}
                                        classes={{ root: 'ir-tisa-font' }} key={v4()} value={opt}>{opt}</MenuItem>)

                                    }
                                </Select>
                            </FormControl>
                            {
                                _batchId && sessionDate && description ?
                                    <div style={{ padding: '8px 16px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                                <span style={{ color: '#545454' }}>Previous session for above batch</span>
                                                <span style={{ color: '#8A8A8A ' }}>{description}</span>
                                            </div>
                                            <div style={{ display: 'flex', width: '60%', gap: 10, justifyContent: 'space-evenly', color: '#8A8A8A ' }}>
                                                <span className='helperIcons'><DateRangeIcon />{moment(sessionDate).format('DD/MM/YYYY') || ''}</span>
                                                <span className='helperIcons'><ScheduleIcon />{moment(updatedAt).format('h:mma') || ''}</span>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                        </div > : null}
                    <CoTrainerDropDown
                        checkFields={checkFields}
                        label={"Co-Trainer's Name"}
                        red_coTrainersForBatch={red_coTrainersForBatch}
                        batchDetails={batchDetails}
                        value={{ 'fullname': batchDetails?.coTrainer?.name || '', phone: batchDetails?.coTrainer?.phone || '', id: 1 }}
                        handleChange={(value) => {
                            setBatchDetails((old) => ({
                                ...old,
                                coTrainer: {
                                    ...old.coTrainer,
                                    phone: value?.phone || '',
                                    id: value?.id === 1 || value?._id === 1 ? batchDetails?.coachId : value?.id,
                                    name: value?.fullname,
                                }
                            }))
                        }
                        }
                    />
                    <BottomButton
                        style={{ margin: '0.8rem 0rem 0rem' }}
                        setCheckFields={setCheckFields}
                        _validtor={() => (selectedBatch || latestBatch) && batchDetails?.coTrainer && batchDetails?.coTrainer.id ? false : true}
                        text={'Conduct'}
                        onClickFunction={resumeAbleSessionForBatch ? () => {
                            setRedirect({ status: 'loading' });
                            setRedirect({ status: true, to: `application/conduct/${resumeAbleSessionForBatch}?moduleId=${__moduleId}` })
                        } : createSessionFunction}
                    />
                </div>
            }
            {!showBatchDropDown ? null : <div style={{ fontSize: "0.8rem", padding: "0.5rem 1rem", textAlign: "right", cursor: "pointer", color: "#999999", fontSize: '14px', margin: '-1.5vh 0vh 2vh 0vh' }} onClick={() => setShowBatchDropDown(!showBatchDropDown)}>view older</div>}
        </div >
    }
}
const CoTrainerDropDown = ({ batchDetails, checkFields, value, red_coTrainersForBatch, handleChange }) => {
    let options = [];
    red_coTrainersForBatch.reduce((result, item, i) => {
        if (item?.id !== batchDetails.coachId)
            if (item?.meta?.delhi_police?.policestation !== 'Guest')
                options.push(result[item?.fullname] = item)
        return result;
    }, {});
    return <ComboBox
        newBatchCretion={true}
        checkFields={checkFields}
        options={options.length > 0 ? options : [{ fullname: 'Conducting Session Alone', id: 1, phone: 1 }]}
        value={value}
        handleChange={handleChange}
    />

}
const TextFieldEntry = ({ label, checkFields, batchDetails, setBatchDetails, batchName }) => {
    const classes = useStyles();
    let value = batchDetails?.newBatch?.length == 0 ? batchDetails?.newBatch : batchDetails?.newBatch || batchName
    let chk = !checkFields || value;
    let d_date = moment().format('DD/MM/YYYY')
    return <TextField
        error={chk ? false : true}
        helperText={chk ? '' : `Please Enter ${label}`}
        fullWidth
        label={label}
        variant="outlined"
        InputLabelProps={{ classes: { root: classes.textFieldClass } }}
        InputProps={{
            classes: { root: (value) ? classes.inputFilled : classes.inputEmpty, }
        }}
        value={value}
        onChange={(event) => {
            setBatchDetails((old) => ({
                ...old,
                newBatch: event.target.value
            }))
        }}
    />
}

const MetaSelection = ({ _editbatchDetails, field, key, batchMeta, checkFields, handleChange, setBatchMeta, batchDetails, sessionsForModule, edit }) => {
    const classes = useStyles();
    let { fieldType, values, selectedValue, metaKey, fieldName, type } = field || {}
    let latestBatch = batchDetails?.latestBatch
    let { meta } = _editbatchDetails || sessionsForModule?.[latestBatch] || {}
    let value = batchMeta?.[metaKey]

    let chk = !checkFields || (edit ? (value?.length == 0 ? value : value || meta?.[metaKey]) : value)

    if (fieldType == 'dropdown') {
        return (
            <FormControl key={metaKey || v4()} variant="outlined" fullWidth error={chk ? false : true}>
                <InputLabel id="ir-select-batch-label" style={{ fontSize: '14px' }}>{fieldName}</InputLabel>
                <Select
                    key={v4()}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    style={{ cursor: selectedValue ? "no-drop" : 'pointer' }}
                    label={fieldName}
                    MenuProps={{ classes: { paper: classes.select } }}
                    className={value ? classes.selectBorder : ''}
                    value={value}
                    defaultValue={edit ? meta?.[metaKey] : ''}
                    displayEmpty
                    onChange={(ev) => {
                        handleChange(ev.target.value, metaKey, fieldName, meta, edit)
                        ev.stopPropagation();
                        ev.preventDefault();
                    }}
                >
                    {values?.map(opt =>
                        <MenuItem
                            style={{ fontSize: '1rem', fontWeight: 400, color: '#49454F' }}
                            key={opt} value={opt}>
                            {opt}
                        </MenuItem>
                    )}
                </Select>
                <FormHelperText>{chk ? "" : `Please Select ${fieldName}`}</FormHelperText>
            </FormControl >)
    }
    else if (fieldType == 'textfield') {
        return (<TextField
            key={key}
            type={type ?? ''}
            error={chk ? false : true}
            helperText={chk ? ' ' : `Please Enter ${fieldName}`}
            style={{ cursor: selectedValue ? "no-drop" : 'pointer' }}
            fullWidth
            InputLabelProps={{ classes: { root: classes.textFieldClass } }}
            InputProps={{
                classes: { root: (value) ? classes.inputFilled : classes.inputEmpty, },
            }}
            label={`${fieldName}`
            }
            variant="outlined"
            defaultValue={edit ? meta?.[metaKey] : ''}
            value={value}
            onChange={(ev) => {
                handleChange(ev.target.value, metaKey, fieldName, meta, edit)
                ev.stopPropagation();
                ev.preventDefault();
            }}
        />)
    }
    else if (fieldType == 'conditional_filter' && !edit) {
        return <ConditonalFilter
            // _value={{[metaKey]: meta?.[metaKey]}}
            batch={true}
            field={field}
            checkFields={checkFields}
            onChange={() => { }}
            forBatch={(key, value) => setBatchMeta((old) => ({ ...old, [key]: value }))}
        />
    }
    else
        return null
}



const BatchScene = ({ values, fields }) => {
    let { coTrainerId, handleCloseInfo, moduleIndex, batchEdit, setEndSessionPopup, _editbatchDetails, firstModule, showBatchComponent, app, selectedBatch, sessionsForModule, moduleDetails, red_coTrainersForBatch, user_token, moduleId, coachId, coTrainer, batchMeta, programId, moduleIndexText, setSessionsForModule, setBatchMeta, latestBatch, batchDetails, setBatchDetails, handleChange, checkFields, setRedirect, setCheckFields } = values || {}

    const [showMore, setShowMore] = useState(false)
    let d_date = moment().format('DD/MM/YYYY')
    let newBatch = batchDetails?.newBatch


    async function createNewBatchAndSession() {
        setRedirect({ status: 'loading' });
        await createNewBatch(user_token, {
            batchName: `${d_date}_${newBatch}`,
            coachId: [...new Set([coachId, coTrainer.id])],
            meta: batchMeta,
            startingDate: new Date()
        }).then(async (res) => {
            await createSession({
                user_token, moduleId, coach_id: coachId,
                facilitators: [...new Set([coachId, coTrainer.id])],
                sessionType: 'pre',
                sessionName: new Date(),
                batchId: res._id || res.id,
                meta: { ...batchMeta, programId } || {}
            }, () => { })
                .then(res => {
                    if (res && res.moduleId && res) {
                        setRedirect({ status: true, to: `application/conduct/${res.id}?moduleId=${moduleId}` })
                    }
                })
        }).catch((err) => console.log(err))
    }

    async function createSessionFunction() {
        try {
            if (selectedBatch || latestBatch) {
                setRedirect({ status: 'loading' });
                await createSession({
                    user_token, moduleId, coach_id: coachId,
                    facilitators: [...new Set([coachId, coTrainer.id])],
                    sessionType: 'pre',
                    sessionName: new Date(),
                    batchId: selectedBatch || latestBatch,
                    meta: { ...sessionsForModule?.[selectedBatch || latestBatch]?.meta, programId } || {}
                }, () => { }).then(res => {
                    if (res && res.moduleId && res) {
                        setRedirect({ status: true, to: `application/conduct/${res.id}?moduleId=${moduleId}` })
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }
    }

    let coTrainerName = Object.values(red_coTrainersForBatch || {}).reduce((res, s) => {
        if (s.id == coTrainerId) {
            res['fullname'] = s?.fullname
            res['phone'] = s?.phone
            res["id"] = s.id
        }
        return res
    }, {});

    const description = moduleDetails?.[moduleId]?.description;

    function newBatchComponent(id, _edit) {
        let edit = _edit || false;

        return <div style={{ padding: '0.5em 1em', display: 'flex', flexDirection: 'column', gap: '0.5rem' }} id={id}>
            <div style={{ color: "#999999", fontSize: "1rem", fontWeight: 400, display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0rem 0rem 0.8rem' }}>
                {moduleIndexText ? `${moduleIndexText}: ${description}` : `${moduleIndex + 1}: ${moduleDetails?.[moduleId]?.description}`}

                < Icon style={{ cursor: "pointer" }} onClick={() => {
                    handleCloseInfo()
                    store.dispatch({ type: 'EMPTY_COTRAINERS' })
                }} path={mdiClose}
                    size={1}
                    color='black' />
            </div>
            <>
                <div style={{ display: 'flex', width: "100%", gap: '1.5rem', justifyContent: 'space-between' }}>
                    <TextFieldEntry
                        checkFields={checkFields}
                        label={'Batch Name'}
                        batchDetails={batchDetails}
                        batchName={edit ? _editbatchDetails?.batchName : ''}
                        setBatchDetails={setBatchDetails}
                    />
                    <MetaSelection
                        _editbatchDetails={_editbatchDetails}
                        handleChange={handleChange}
                        checkFields={checkFields}
                        field={{
                            "fieldName": "No of Participants", "fieldType": "textfield",
                            "metaKey": "No of Participants", "type": "number",
                            "mandatory": true, "values": [""]
                        }}
                        key={"No_ofP"}
                        edit={edit}
                        batchDetails={batchDetails}
                        sessionsForModule={sessionsForModule}
                        batchMeta={batchMeta}
                        setSessionsForModule={setSessionsForModule}
                        setBatchMeta={setBatchMeta}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '1rem', }}>
                    {fields && fields?.length > 0 && (fields || [])
                        .filter((field) => !edit
                            ? true : !fields.some(field => field?.priority)
                                ? true : showMore ?
                                    true : field?.priority)
                        .map((field, i) => {
                            return (<MetaSelection
                                _editbatchDetails={_editbatchDetails}
                                checkFields={checkFields}
                                key={i}
                                handleChange={handleChange}
                                field={field}
                                batchDetails={batchDetails}
                                sessionsForModule={sessionsForModule}
                                batchMeta={batchMeta}
                                setSessionsForModule={setSessionsForModule}
                                setBatchMeta={setBatchMeta}
                                edit={edit}
                            />)
                        })
                    }
                </div>
                {edit && fields?.some(field => field?.priority) && !showMore && <div style={{ cursor: 'pointer', display: "flex", justifyContent: 'flex-end', alignItems: 'center', fontSize: 12 }} onClick={() => setShowMore(true)}> Show More....<ExpandMoreIcon /></div>}
            </>
            <CoTrainerDropDown
                checkFields={checkFields}
                label={"Co-Trainer's Name"}
                red_coTrainersForBatch={red_coTrainersForBatch}
                batchDetails={batchDetails}
                value={edit && !batchDetails?.coTrainer?.name ? coTrainer?.name?.length == 0 ? { 'fullname': coTrainer.name || '', phone: coTrainer.phone || '', id: 1 } : coTrainerName : { 'fullname': coTrainer.name || '', phone: coTrainer.phone || '', id: 1 }}
                handleChange={(value) => {
                    setBatchDetails((old) => ({
                        ...old,
                        coTrainer: {
                            ...old.coTrainer,
                            id: value?.id === 1 || value?._id === 1 ? batchDetails?.coachId : value?.id,
                            name: value?.fullname || '',
                            phone: value?.phone || ''
                        }
                    }))
                }
                }
            />
            <BottomButton
                style={{ margin: '0.8rem 0rem 0rem' }}
                setCheckFields={setCheckFields}
                _validtor={() => {
                    let a = fields?.length > 0 ?
                        fields.some(el => el.fieldType == 'conditional_filter') ? fields[0]._schema.length + fields?.length - 1 : fields?.length : false
                    return edit ? false : Object.keys(batchMeta || {}).length - 2 == a && batchDetails?.newBatch?.length > 0 && coTrainer.id ? false : true
                }}
                text={edit ? 'Save' : 'Create Batch'}
                onClickFunction={async () => {
                    if (!edit) createNewBatchAndSession()
                    else if (edit) {
                        await updateBatch(user_token, selectedBatch, {
                            batchName: batchDetails?.newBatch,
                            coachId: [...new Set([coachId, (coTrainer?.id || coTrainerId)])],
                            meta: batchMeta,
                        }).then(res => handleCloseInfo(res))
                    }
                    return;
                }}
            />
        </div>
    }
    function resumeSession(sessionId) {
        setRedirect({ status: true, to: `application/conduct/${sessionId}?moduleId=${moduleId}` })
    }
    function endSessionFunction(sessionId) {
        endSession({ user_token, sessionId });
        handleCloseInfo();
    }
    if (batchEdit) {
        return newBatchComponent('_di1', true)
    }
    else if (Object.keys(sessionsForModule || {}).length == 0) {
        if (firstModule) { return newBatchComponent('_di1') }
        return <div style={{ display: 'flex', justifyContent: "space-between" }}>
            Batch can be created only on Module 1.
            < Icon style={{ cursor: "pointer" }} onClick={() => {
                handleCloseInfo()
                store.dispatch({ type: 'EMPTY_COTRAINERS' })
            }} path={mdiClose}
                size={1}
                color='black' />
        </div>
    }
    else {
        return <BatchDropDown
            moduleIndexText={moduleIndexText}
            setRedirect={setRedirect}
            setEndSessionPopup={setEndSessionPopup}
            __moduleId={moduleId}
            handleCloseInfo={handleCloseInfo}
            newBatchComponent={newBatchComponent}
            firstModule={firstModule}
            resumeSession={(id) => resumeSession(id)}
            endSession={(id) => endSessionFunction(id)}
            setCheckFields={setCheckFields}
            moduleIndex={moduleIndex}
            app={app}
            label={'Select Batch Name'}
            sessionsForModule={sessionsForModule}
            moduleDetails={moduleDetails || {}}
            latestBatch={latestBatch}
            selectedBatch={selectedBatch}
            setBatchDetails={setBatchDetails}
            createSessionFunction={createSessionFunction}
            checkFields={checkFields}
            red_coTrainersForBatch={red_coTrainersForBatch}
            batchDetails={batchDetails}
            value={{ 'fullname': coTrainer.name || '' }}
            handleChange={(value) =>
                setBatchDetails((old) => ({
                    ...old,
                    coTrainer: {
                        ...old.coTrainer,
                        id: value?.id === 1 || value?._id === 1 ? batchDetails?.coachId : value?.id,
                        name: value?.fullname,
                    }
                }))
            }
        />

    }

}
const BottomButton = ({ style, text, onClickFunction, _validtor, setCheckFields }) => {
    let _disabled = _validtor();
    return <Button
        className='conduct-button-dialog'
        onClick={(ev) => {
            if (ev && ev.detail == 1 && !_disabled) onClickFunction();
            else setCheckFields(true);
            ev.preventDefault();
            return;
        }}
        style={!_disabled ? { color: 'black', ...style } : { color: 'grey', ...style }}
    >
        {text}
    </Button >
}
const BatchCreation = ({ moduleIndexText, moduleIndex, coTrainerId = null, _editbatchDetails = null, batchEdit = false, __latestBatch = null, app, firstModule, coach_id, handleCloseInfo, moduleId, user_token, }) => {

    let { user, moduleDetails, availableProgramsNew, } = app || {}
    let red_coTrainersForBatch = app.coTrainersForBatch || {}
    let { programId, organizationid } = user || {}
    let program = availableProgramsNew[programId] || {}
    const [sessionsForModule, setSessionsForModule] = useState(null)
    const [batchMeta, setBatchMeta] = useState({ programId, ..._editbatchDetails?.meta })
    let [batchDetails, setBatchDetails] = useState({
        showBatchComponent: false, coTrainerId,
        latestBatch: null, coTrainer: '', coachId: coach_id, newBatchId: null, selectedBatch: __latestBatch || null,
        newBatch: _editbatchDetails?.batchName || null

    })

    const [endSessionPopup, setEndSessionPopup] = useState({ open: false, sessionId: null });
    const [checkFields, setCheckFields] = useState(false)
    const [redirect, setRedirect] = useState({ status: false, to: '' })

    const { showBatchComponent, latestBatch, coTrainer, coachId, newBatchId, selectedBatch } = batchDetails
    const { batchCreationFields, trainerAccessCodes, coTrainerAccessCode } = program || {}
    const [configType, setConfigType] = useState(batchCreationFields?.configType)

    let popUpHTml = '<ul><li>Ensure the session is conducted in <strong>full screen</strong> mode.</li><li>Ensure participants have <strong>installed the app</strong> and are ready.</li><li>Ensure <strong>all slides</strong> are covered in the session.</li><li>Ensure <strong>all videos</strong> have been played.</li><li>Ensure all participants <strong>complete the assessment</strong> after the session</li><li>Ensure all participants have given <strong>feedback/ testimonials.</strong></li></ul>'

    useEffect(() => {
        !sessionsForModule && coach_id && user_token &&
            getBatchForCoachAndModule({ user_token, coachId: coach_id, moduleId })
                .then((res) => {
                    if (res.length > 0) {
                        const _res = res
                            .filter(batch => batch?.meta?.programId === programId)
                            .sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt));
                        const _batch = _res.reduce((result, a) => {
                            result[a._id] = a.coachId.includes(coach_id) ? a : {}
                            return result
                        }, {})
                        setSessionsForModule(_batch)
                        setBatchDetails((old) => ({ ...old, latestBatch: __latestBatch || _res?.[0]?._id || null }))
                    }
                    else setSessionsForModule({})
                });
    }, [coach_id, user_token])

    useEffect(() => {
        let a = async () => await getUser(user_token, organizationid, { coTrainerAccessCode })
        if (coTrainerAccessCode && user_token && organizationid && Object.keys(red_coTrainersForBatch || {}).length == 0) {
            a()
        }
    }, [coTrainerAccessCode, user_token, organizationid])

    let handleChange = (e, metaKey, fieldName, meta, edit) => {
        if (!edit) setBatchMeta((old) => ({ ...old, [metaKey]: e }))
        else { setBatchMeta((old => ({ ...old, ...meta, [metaKey]: e }))) }
    }

    let title = {
        "ACCESSPREVIOUSBATCHES": 'Conducting a Session',
        "CREATEBATCH": 'Creating a Batch',
        "EDITBATCH": 'Edit Batch Details'
    }



    return <>
        {/* <div
            id='parnet1010'
            style={{ paddingBottom: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem 2.5rem', fontSize: "20px", }}
            className='ap-conduct-dialog-close-session-button'
        >

            {/* {!app?.isLoading && !(redirect.status === 'loading') && <div className='titleCss' style={{ display: 'flex', alignItems: 'center', }}>
                {configType !== batchCreationFields?.configType && <ArrowBackIosIcon
                    onClick={() => setConfigType(batchCreationFields?.configType)}
                />}
                {title[configType] ?? ''}
                {/* {title[configType] ?? 'Conducting Session For'} */}
        {/* </div>} */}

        {/* {!app?.isLoading && !(redirect.status === 'loading') && < Icon onClick={() => {
                handleCloseInfo()
                store.dispatch({ type: 'EMPTY_COTRAINERS' })
            }} path={mdiClose}
                size={1}
                color='black' />
            } */}
        {/* </div> */}
        <div className="scrollDiv" style={{ height: "max-content", maxHeight: '74vh', }}>
            {app.isLoading || (redirect.status === 'loading') ?
                <div className='w-100 h-100 flex-center'>
                    <CircularProgress classes={{ root: 'circular-progress-color my-1' }} size={50} />
                </div> :
                <BatchScene
                    values={{
                        moduleIndexText,
                        handleCloseInfo,
                        setEndSessionPopup, coTrainerId, moduleIndex,
                        batchEdit, _editbatchDetails,
                        firstModule, handleCloseInfo, showBatchComponent, app, selectedBatch, sessionsForModule, moduleDetails, red_coTrainersForBatch, user_token, moduleId, coachId, coTrainer, batchMeta, programId, setSessionsForModule, setBatchMeta, latestBatch, batchDetails, setBatchDetails, setConfigType, handleChange, checkFields, setRedirect, setCheckFields
                    }}
                    fields={batchCreationFields?.fields}
                />
            }
        </div>
        <EndSessionPopup
            texts={{ text: 'All tool data if any in the module, would be reset.', confirmText: 'Yes, end session', rejectText: "No, go back" }}
            parentId="parnet1010"
            open={endSessionPopup.open}
            handlePopup={(prop, end) => {
                setEndSessionPopup(prop);
                if (end) {
                    endSession({
                        user_token,
                        sessionId: endSessionPopup.sessionId
                    });
                }
            }}
        />
        {redirect.status === true && <Redirect to={redirect.to} push={true} />}
    </>
}
export default BatchCreation;