import config from "../../config"
import _ from "lodash";
import { now } from "moment";
import { sendEventAPI } from "./redux_actions";
import { connectToANode, listenToChildAdd, offNode, pushInNode, writeData } from "../../utilities/firebase/fb_rtdb";

var stop = true;
var eventQueue = [];
let eventList = [];
var eventTypeAndHandler, senderType, senderId, receiveId, sessionid, _receiveType, _engagementId
var engagementRef, participantRef
let enableEngagementTarget, engagementTarget, participantTarget;



async function initalize(engagementId, etah, userType, userId, receiveType, otp) {
    // Stoing Variables    
    eventTypeAndHandler = etah
    senderType = userType
    senderId = userId
    _receiveType = receiveType
    _engagementId = engagementId
    const session = await getSessionDetail(otp)
    if (session) {
        sessionid = session._id
        if (receiveType === "Student")
            receiveId = sessionid
        else if (receiveType === "Coach")
            receiveId = session.coachId

        // enableEngagementRef = dataRef.child('test').child("live_session").child(sessionid).child("enableEngagement")
        // engagementRef = dataRef.child('test').child("live_session").child(sessionid).child(_engagementId)
        enableEngagementTarget = `test/live_session/${sessionid}/enableEngagement`
        engagementTarget = `test/live_session/${sessionid}/${_engagementId}`
    }
}

async function startSession(sessionid, etah) {
    // console.log("sessionid", sessionid)
    eventTypeAndHandler = etah
    stop = false
    participantTarget = `test/live_session/${sessionid}/Participants`
    // participantRef = dataRef.child()
    connectToANode({
        refString: participantTarget, f: (snapshot) => {
            let participants = snapshot
            for (let participant in participants) {
                for (let event in participants[participant])
                    eventQueue.push(participants[participant][event])
            }
        }
    })
    // console.log("eventQueue before loop", eventQueue)
    loop()
}

async function stopSession(sessionid) {
    offNode(participantRef)
}

async function startEngagement() {
    receiveEvent()
    loop()
}

async function getSessionDetail(otp) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        otp
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    let session

    // await fetch("http://localhost:5001/v1/session/getSessionByOTP", requestOptions)
    await fetch(config.API_URL + "session/getSessionByOTP", requestOptions)
        .then(response => response.json())
        .then(result => { if (!result.code) return session = result })
        .catch(error => console.log('error', error));

    return session;
}

function receiveEvent() {
    stop = false
    listenToChildAdd({
        refString: engagementTarget, f: (payload_key, payload) => {
            if (_.filter(eventList, _.matches(payload_key)).length === 0) {
                eventQueue.push(payload)
                eventList.push(payload_key)
            }
        }
    })
}

async function loop() {
    while (!stop || eventQueue.length > 0) {
        let first = eventQueue.shift();
        if (first) {
            eventTypeAndHandler(first);
        }
        else
            await sleep(1)
    }
}

async function sendEvent(eventName, eventData) {
    let payload = {
        senderType, senderId, eventName, eventData, receiveType: _receiveType, receiveId, startedAt: now()
    }
    switch (eventName) {
        case "START":
        case "STOP":
            await writeData(`${enableEngagementTarget}/${eventData}`, eventData)
            await writeData(`${enableEngagementTarget}/${eventData}`, payload)

            sendEventAPI(undefined, { newAPI: true, sessionId: sessionid, engagementId: _engagementId, payload })
            break;
        default:
            // let now = new Date();
            pushInNode(engagementTarget, payload)
            sendEventAPI(undefined, { newAPI: true, sessionId: sessionid, engagementId: _engagementId, payload })
            break
    }
}


function stopEngagement() {
    stop = true;
    offNode(engagementRef)
}

function sleep(second) {
    return new Promise(resolve => setTimeout(resolve, second * 1000));
}



export default {
    initalize,
    sendEvent,
    stopEngagement,
    startEngagement,
    startSession,
    stopSession,
}