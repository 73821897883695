import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import EnhancedTable from './TableMUI'
import { editBatch, getBatchAnalytics } from '../application/redux_actions'
import { Button, CircularProgress, Dialog, TextField } from '@material-ui/core'
import NewBatchCreation from '../IndianRailways/ConductDialog/newBatchCreation'


const BatchDetails = ({ userId, selected, batchList }) => {

    const user_token = useSelector(state => state.auth?.user_token)
    const app = useSelector(state => state.app)
    const [open, setOpen] = useState(false)
    const [otps, setOtps] = useState(undefined);
    const [batchMeta, setBatchMeta] = useState(undefined);
    const [participants, setParticipants] = useState(undefined);
    const [batchDetails, setBatchDetails] = useState(null);

    useEffect(async () => {
        if (user_token && selected.batchId) {
            await getBatchAnalytics({ user_token, batchId: selected.batchId }).then(data => {
                setOtps(data.assessmentOtps);
                setBatchMeta(data.batchMeta[0].meta);
                setParticipants(data.data);
            })
            await editBatch({ user_token, batchId: selected.batchId }).then(res => {
                setBatchDetails(res)
            })
        }

    }, [user_token, selected.batchId])

    if (!participants || !batchMeta || !otps) {
        return <div>Loading...</div>
    }


    return <>
        <div className='flex flex-col h-100'>
            <div className="overflow-y-auto">
                <div className='flex justify-between fit-content w-100'>
                    <div className='select-batch'>
                        <div className='font-bold'>{selected["Batch Name"]}</div>
                    </div>
                    <Button disabled={Object.keys(batchDetails || {}).length == 0} onClick={() => setOpen(true)}>Edit Batch</Button>
                </div>

                <div className='flex w-auto p-white align-center font-bold bg-white my-16 fit-content'>
                    {Object.entries(batchMeta).filter(([k, v]) => !(k === "programId") && typeof (v) == 'string').map(([key, value]) => {
                        return <div key={key + value} className='flex justify-around gap-4'>
                            <div className='p-16-4'>{key}</div>
                            <div className='p-16-4 bg-gray br-10'>{value}</div>
                        </div>
                    })}
                </div>

                <div className='flex w-auto p-white gap-8 align-center bg-white my-16 fit-content'>
                    {Object.entries(otps).filter(([k, v]) => v?.otp.toString()).map(([key, value]) => {
                        return <div key={key}>
                            <div className='font-medium text-center'>{key}(Code - {value.otp})</div>
                            <div className='bg-gray br-10 font-bold text-center p-32-4'>{value.students}</div>
                        </div>
                    })}

                </div>
                <div className='bg-white my-16 p-white'>
                    <div className='font-bold'>Batch Participants</div>
                </div>
                <EnhancedTable defaultRows={25} list={participants} handleBatchClick={() => { }} tableType={"batchAnalytics"} />
            </div>
        </div>
        <Dialog
            aria-labelledby="info-dialog-title"
            open={open}
            maxWidth={false}
            classes={{}}
            className='ir-tisa-font'
        >
            <NewBatchCreation
                _editbatchDetails={batchDetails?.batch}
                coTrainerId={batchDetails?.batch?.coachId?.[1] || batchDetails?.batch?.coachId?.[0]}
                __latestBatch={selected.batchId}
                batchEdit={true}
                app={app}
                firstModule={true}
                coach_id={userId}
                handleCloseInfo={(res) => {
                    if (res) {
                        setBatchDetails(res)
                        setBatchMeta(res.meta)
                    }
                    setOpen(false);
                }}
                moduleId={'6548c703a023bfda2cfbf792'}
                user_token={user_token}
            />

        </Dialog >
    </>
}

export default BatchDetails;