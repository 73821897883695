import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import NavLink from "./NavLink";
import { connect } from "react-redux";
import './style.css'
import { t } from "i18next";
import Profile from "../../../components/Profile";
import Modal from "../../../components/walkThrough/modal";
import { getAnalyticsForProgram } from "../../application/redux_actions";

const styles = {};
const logo = require('./assets/pedgog.svg');
const grp = require('./assets/conduct.svg');
const resourceSvg1 = require('./assets/resourceSvg1.svg');
class LeftNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accesscode: "",
      animate: false,
      offline: false,
      language: props.language
    }
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    this.setState((prevState) => {
      return { animate: !prevState.animate }
    });
  }

  async componentDidMount() {
    let { user } = this.props.app || {}
    let { user_token } = this.props.auth || {}
    if (user && user_token && user.programId) {
      getAnalyticsForProgram({ token: user_token, userId: user._id })
    }
  }

  routeChange() {
    let path = `/application`;
    this.props.history.push(path);
  }


  render() {
    let { walkThrough, user, availableProgramsNew } = this.props.app || {}
    let { programId, analytics, ratingAccess } = user || {}
    let program = availableProgramsNew[programId] || {}
    let { showResource } = program || {}
    let { currentEl, allElements, stop } = walkThrough;

    const isAnalyticsAvailable = (analytics || []).filter(a => a?.category == "coachAnalytics").map(a => a);



    return (
      <div className={"ap-left-nav h-100"}>
        <form className={"h-100"}>
          <nav className={"h-100"}>
            <ul className={"h-100"} >
              <div>
                <div style={{ padding: '1rem' }}>
                  <img src={logo} alt="logo" style={{ display: 'block', margin: 'auto' }} />
                </div>
                <div className={!stop && allElements[currentEl] == 'walkProgramDiv' ? 'zIndexPos' : ''}>
                  {!stop && allElements[currentEl] == 'walkProgramDiv' && <Modal id={'walkProgramDiv'} Comp={false} />}
                  <NavLink to="/application" className={!stop && allElements[currentEl] == 'walkProgramDiv' ? 'onClickDisabled' : ''}>
                    <img src={grp} alt="grp" className="ap-nav-img" />
                    <label className='ap-nav-link-profile-text'>{t('PROGRAM')}</label>
                  </NavLink>
                </div>
                {showResource ?
                  <div className={!stop && allElements[currentEl] == 'showBlog' ? 'zIndexPos' : ''}>
                    {!stop && allElements[currentEl] == 'showBlog' && <Modal id={'showBlog'} Comp={false} />}
                    <NavLink to="/application/blog" id={!stop && allElements[currentEl] == 'showBlog' ? 'showBlog' : ''}>
                      <img src={resourceSvg1} alt="grp" className="ap-nav-img" />
                      <label className='ap-nav-link-profile-text'>{t('RESOURCES')}</label>
                    </NavLink>
                  </div>
                  : null}
                {isAnalyticsAvailable?.length > 0 ? <NavLink to="/batches" className={!stop && allElements[currentEl] == 'walkProgramDiv' ? 'onClickDisabled' : ''}>
                  <img src={grp} alt="grp" className="ap-nav-img" />
                  <label className='ap-nav-link-profile-text'>{t('BATCHES')}</label>
                </NavLink> : null}
                {/* {analytics?.dashboard?.length > 0 ?
                  <div className={!stop && allElements[currentEl] == 'analytics' ? 'zIndexPos' : ''}>
                    {!stop && allElements[currentEl] == 'analytics' && <Modal id={'analytics'} Comp={false} />}
                    <NavLink to="/application/analytics" id={!stop && allElements[currentEl] == 'analytics' ? 'analytics' : ''}>
                      <img src={grp} alt="grp" className="ap-nav-img" />
                      <label className='ap-nav-link-profile-text'>{t('ANALYTICS')}</label>
                    </NavLink>
                  </div>
                  : null} */}
                {/* {ratingAccess ? <NavLink to="/application/rating">
                  <img src={grp} alt="grp" className="ap-nav-img" />
                  <label className='ap-nav-link-profile-text'>{t('RATING')}</label>
                </NavLink> : null} */}
              </div>
              <div className='last-div' style={{ justifyContent: 'flex-end' }}>
                {/* <NavLink to="/logout">
                  <img src={logoutIcon} alt="ap-nav-img" className="ap-nav-img" />
                  <label>{t('LOGOUT')}</label>
                </NavLink> */}
                <Profile />
              </div>
            </ul>
          </nav>
        </form>
      </div >
    );
  }
}

LeftNav.propTypes = {
  classes: PropTypes.object.isRequired,
  authMsg: PropTypes.string
};

const mapStateToProps = state => ({
  auth: state.auth,
  app: state.app
});

export default connect(mapStateToProps, null)(withStyles(styles)(LeftNav));