import React, { useEffect, useState } from 'react'
import InputField from './Input';
import store from '../../../Redux_Store';
import { sendOTP, loginWithOTP } from '../../auth/redux_actions';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Redirect } from 'react-router';

const useStyles = makeStyles((theme) => ({
    buttonRoot: {
        height: "68px",
        borderRadius: "17px",
        '&:hover': {
            backgroundColor: '#e4001b !important',
            color: 'white',
        },
    }
}));


export default ({ auth, app, setMobileType }) => {
    let timer;

    const [resendTimer, setResendTimer] = useState(true);
    const classes = useStyles();
    const [redirect, setRedirect] = useState()

    useEffect(() => {
        store.dispatch({ type: "USER_VALUE_CHANGE", payload: { key: 'error', value: '' } });
        store.dispatch({ type: "RESET_LOGIN_OTP" });
        if (app.userValues.otpTimer !== 0 && resendTimer)
            otpTimerFn(true);
        return () => {
            clearInterval(timer);
        }
    }, [])


    const phoneValidation = (value, limit) => {
        let regex = new RegExp('^\\d{0,' + limit + '}$');
        if (regex.test(value))
            return true
        return false
    }

    const handleChange = (event, prop) => {
        if (prop === 'otp') {
            if (phoneValidation(event, 6))
                store.dispatch({ type: "USER_VALUE_CHANGE", payload: { key: prop, value: event } });
        }
        else if (prop === 'phone') {
            if (phoneValidation(event.target.value, 10))
                store.dispatch({ type: "USER_VALUE_CHANGE", payload: { key: prop, value: event.target.value } });
        }
        else
            store.dispatch({ type: "USER_VALUE_CHANGE", payload: { key: prop, value: event.target.value } });
    };


    const handleLogin = () => {
        loginWithOTP({ phone: app.userValues.phone, otp: app.userValues.otp }).then(res => {
            if (res?.code)
                store.dispatch({ type: "USER_VALUE_CHANGE", payload: { key: 'error', value: "Please check and re-enter OTP" } });
            else if (!res.isAlreadyRegister) {
                setRedirect("/auth/register")
            }
            else store.dispatch({ type: "LOGIN_WITH_OTP", payload: res });
        }).catch((error) => {
            store.dispatch({ type: "SEND_OTP_FAILED" });
            store.dispatch({ type: "USER_VALUE_CHANGE", payload: { key: 'error', value: error } });
        })
    }

    const otpTimerFn = (oneTime) => {
        if (oneTime)
            setResendTimer(false);
        let counter = app.userValues.otpTimer === 0 ? 60 : app.userValues.otpTimer;
        timer = setInterval(() => {
            if (counter >= 0) {
                counter = counter - 1;
                store.dispatch({ type: "USER_VALUE_CHANGE", payload: { key: 'otpTimer', value: counter } });
            }
            else clearInterval(timer);
        }, 1000);

        setTimeout(() => {
            clearInterval(timer);
        }, counter * 1000)
    }

    const sendOtp = (resend) => {
        if (phoneValidation(app.userValues.phone, 10) && (app.userValues.phone).length === 10) {
            store.dispatch({ type: "SEND_OTP_ATTEMPT" });
            sendOTP(app.userValues.phone, 'LOGIN_OTP').then(res => {
                if (res?.code) {
                    if (resend === true) {
                        store.dispatch({ type: "SEND_OTP_FAILED" });
                        store.dispatch({ type: "USER_VALUE_CHANGE", payload: { key: 'error', value: "Please check and re-enter OTP" } });
                    }
                    else {
                        store.dispatch({ type: "SEND_OTP_FAILED" });
                        store.dispatch({ type: "USER_VALUE_CHANGE", payload: { key: 'error', value: "Please check and re-enter mobile number" } });
                    }
                }
                else {
                    store.dispatch({ type: "USER_VALUE_CHANGE", payload: { key: 'error', value: '' } });
                    if (resend === true) otpTimerFn(false);
                }
            }).catch(error => {
                store.dispatch({ type: "SEND_OTP_FAILED" });
                store.dispatch({ type: "USER_VALUE_CHANGE", payload: { key: 'error', value: error } });
            });
        }
        else {
            store.dispatch({ type: "SEND_OTP_FAILED" });
            store.dispatch({ type: "USER_VALUE_CHANGE", payload: { key: 'error', value: 'Please check and re-enter mobile number' } });
        }
    }

    if (redirect)
        return <Redirect to={redirect} />
    else
        return <div>
            {
                !(app?.loginOTP?.success) ?
                    <>
                        <div className='ir-login-welcome'>Welcome!</div>
                        <InputField type='Text' field='phone' values={app.userValues} handleChange={handleChange} placeHolder='Enter 10-digit Mobile number' />
                        {app.userValues.error && <div className='ir-error-msg'>
                            {app.userValues.error}
                        </div>}
                        {app.loginOTPLoading ?
                            <div className='text-center w-100'>
                                <CircularProgress classes={{ root: 'circular-progress-color' }} size={50} />
                            </div> : <Button className='login-button-ap' onClick={sendOtp} classes={{ label: classes.buttonLabel, root: classes.buttonRoot }} style={{}}>
                                GET OTP
                            </Button>}
                    </>
                    :
                    <>
                        <ArrowBackIcon onClick={() => store.dispatch({ type: 'RESET_LOGIN_OTP' })} />
                        <div className='ap-login-welcome-font-color'>Enter OTP</div>
                        <div className='ir-otp-login-helper-text' >We've sent a 6-digit OTP to your mobile</div>
                        <div className='bold' style={{ fontSize: '20px' }}>{app?.loginOTP?.phone}</div>
                        <InputField placeHolder='OTP' field='otp' type='otp' handleChange={handleChange} values={app.userValues} />
                        {app.userValues.error && <div className='ir-error-msg'>
                            {app.userValues.error}
                        </div>}
                        <Button className='login-button-ap' onClick={handleLogin} classes={{ label: classes.buttonLabel, root: classes.buttonRoot }}>
                            Verify OTP
                        </Button>
                        <div style={{ color: '#BA1F00', textAlign: 'center', cursor: app.userValues.otpTimer === 0 ? 'pointer' : 'default' }} onClick={(e) => {
                            if (app.userValues.otpTimer === 0) sendOtp(true);
                        }}>
                            {app.userValues.otpTimer === 0 ? 'Resend OTP' : `Resend OTP in ${app.userValues.otpTimer} seconds`}
                        </div>
                    </>
            }
        </div>
}